<template>
  <base-input-text-field
    v-model.trim="internalValue"
    solo
    :label="$t('productDetailPage.individualProduct.labelWeight')"
    :error-messages="errors"
    background-color="white"
    type="number"
    min="0"
    max="200"
    step="1"
  ></base-input-text-field>
</template>

<script>
import BaseInputTextField from '~/components/base/input/BaseInputTextField'

export default {
  name: 'ProductIndividualisationFieldWeight',
  components: { BaseInputTextField },
  props: {
    value: {
      type: String,
      default: '',
    },
    errors: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
}
</script>
